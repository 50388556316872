@font-face {
  font-family: nine;
  src: url(../fonts/D2Coding-Ver1.3.2-20180524.woff2);
  font-weight: normal;
}

@font-face {
  font-family: nine;
  src: url(../fonts/D2CodingBold-Ver1.3.2-20180524.woff2);
  font-weight: bold;
}

html * {
  font-family: nine, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


